import Vue from 'vue';
import VueExposePlugin from '~/util/VueExposePlugin';
import Vuebar from 'vuebar';
import VueYoutube from 'vue-youtube'

Vue.use(VueYoutube)
Vue.use(Vuebar);
Vue.use(VueExposePlugin, {
    $versionRoot: 'test',
    $staticRoot: 'test',
});