import LightBoxState from "../../../data/enum/LightBoxState";

export const IS_MOBILE_SIZE = 'isMobileSize';
export const SHOW_MENU = 'showMenu';
export const ACTIVE_NAV = 'activeNav';
export const SHOW_LIGHTBOX = 'showLightBox';
export const EXPERIENCE_STARTED = 'experienceStarted';
export const BLOCK_SCROLL = 'blockScroll';
export const GO_TO_SECTION = 'goToSection';

export default {
  namespaced: true,
  state() {
    return {
      isMobileSize: false,
      showMenu: false,
      activeNav: false,
      lightBoxData: {},
      lightBoxState: LightBoxState.HIDDEN,
      experienceStarted: false,
      blockScroll: false,
      goSection: '',
    };
  },
  getters: {},
  mutations: {
    [SHOW_MENU]:(state, value) => {
       state.showMenu = value;
     },
    [IS_MOBILE_SIZE]:(state, value) => {
       state.isMobileSize = value;
     },
    [ACTIVE_NAV]:(state, value) => {
       state.activeNav = value;
     },
    [SHOW_LIGHTBOX]:(state, {lightBoxState, data}) => {
       state.lightBoxState = lightBoxState ? lightBoxState : LightBoxState.LOADEDcont;
       state.lightBoxData = data ? data : {title: '', body: '', image: ''};
     },
    [EXPERIENCE_STARTED]:(state, value) => {
       state.experienceStarted = value;
     },
    [BLOCK_SCROLL]:(state, value) => {
       state.blockScroll = value;
     },
    [GO_TO_SECTION]:(state, value) => {
       state.goSection = value;
     }
   },
  actions: {
    [SHOW_LIGHTBOX]({commit}, data) {
      commit(SHOW_LIGHTBOX, data);
    },
    [ACTIVE_NAV]({commit}, data) {
      commit(ACTIVE_NAV, data);
    },
  },
};
