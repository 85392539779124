import Vuex from 'vuex'

import page from './module/page'
import content from './module/content'
import control from './module/control'

const createStore = () => {
  return new Vuex.Store({
    modules: {
     page,
     content,
     control,
    }
  })
}

export default createStore