
export const UPDATE_PAGE = 'updatePage';
export const TRANSITION_IN_PAGE = 'transitionInPage';

export default {
  namespaced: true,

  state() {
    return {
      page: 'index',
      transitionInState: '',
    };
  },
  mutations: {
   [UPDATE_PAGE](state, pageName) {
      state.page = pageName;
    },
   [TRANSITION_IN_PAGE](state, value) {
      state.transitionInState = value;
    }
  }
}