
export const GET_SERVICE_CONTENT = 'getServiceContent';
export const GET_LOCATION_CONTENT = 'getLocationContent';
export const GET_CASE_CONTENT = 'getCaseContent';
export const GET_PAGE = 'getPage';
export const STORE_PAGE = 'storePage';
import Vue from 'vue';

// import butter from '~/util/buttercms';

export default {
  namespaced: true,

  state() {
    return {
        pages: {},
        
    };
  },
  getters: {
    pageByName: (state, getters) => name => {
      return state.pages[name];
    },
  },
  
  mutations: {
   [STORE_PAGE]:(state, {data, pageId}) => {
      // Vue.set(state.pages, pageId, data);
      state.pages = {[pageId]: data, ...state.pages}
    }
  },
  actions: {
    [GET_PAGE]({commit}, {data, pageId}) {
      commit(STORE_PAGE, {data, pageId});
  },
  async [GET_SERVICE_CONTENT]({commit}, serviceSlug) {
      try {
        const response = await this.$axios.$get(`api/service/${serviceSlug}.json`);
        return response;
      } catch (err) {
        console.log(err);
      }
  },
  async [GET_LOCATION_CONTENT]({commit}, locationSlug) {
      try {
        const response = await this.$axios.$get(`api/locations/${locationSlug}.json`);
        return response;
      } catch (err) {
        console.log(err);
      }
  },
  async [GET_CASE_CONTENT]({commit}, caseSlug) {
      try {
        const response = await this.$axios.$get(`api/case/${caseSlug}.json`);
        return response;
      } catch (err) {
        console.log(err);
      }
  },
  }
}