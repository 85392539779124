import content, {GET_PAGE, GET_SERVICE_CONTENT, GET_LOCATION_CONTENT, GET_CASE_CONTENT} from './content';

export const ContentNamespace = 'content';

// export const WorkMutationTypes = {
//     CHANGE_PROJECT_SHOWN: `${PageNamespace}/${CHANGE_PROJECT_SHOWN}`,
//     CHANGE_PROJECT_COLOR: `${PageNamespace}/${CHANGE_PROJECT_COLOR}`,
// };
export const ContentActionTypes = {
    GET_PAGE: `${ContentNamespace}/${GET_PAGE}`,
    GET_SERVICE_CONTENT: `${ContentNamespace}/${GET_SERVICE_CONTENT}`,
    GET_LOCATION_CONTENT: `${ContentNamespace}/${GET_LOCATION_CONTENT}`,
    GET_CASE_CONTENT: `${ContentNamespace}/${GET_CASE_CONTENT}`,
};

export default content;
