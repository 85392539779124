import control, {SHOW_MENU, SHOW_LIGHTBOX, ACTIVE_NAV, EXPERIENCE_STARTED, BLOCK_SCROLL, IS_MOBILE_SIZE, GO_TO_SECTION} from './control';

export const ControlNamespace = 'control';

export const ControlMutationTypes = {
    SHOW_MENU: `${ControlNamespace}/${SHOW_MENU}`,
    IS_MOBILE_SIZE: `${ControlNamespace}/${IS_MOBILE_SIZE}`,
    SHOW_LIGHTBOX: `${ControlNamespace}/${SHOW_LIGHTBOX}`,
    ACTIVE_NAV: `${ControlNamespace}/${ACTIVE_NAV}`,
    EXPERIENCE_STARTED: `${ControlNamespace}/${EXPERIENCE_STARTED}`,
    BLOCK_SCROLL: `${ControlNamespace}/${BLOCK_SCROLL}`,
    GO_TO_SECTION: `${ControlNamespace}/${GO_TO_SECTION}`,
};
export const ControlActionsTypes = {
    SHOW_LIGHTBOX: `${ControlNamespace}/${SHOW_LIGHTBOX}`,
    ACTIVE_NAV: `${ControlNamespace}/${ACTIVE_NAV}`,
};

export default control;
