import { PageActionTypes } from "../store/module/page";
import TransitionPageState from "../data/enum/TransitionPageState";

export default function(context) {
    // go tell the store to update the page
    try {
      context.store.commit(PageActionTypes.UPDATE_PAGE, context.route.name)
      // Only for client side
      if(!process.server) {
        context.store.commit('page/transitionInPage', TransitionPageState.ROUTE_UPDATE);
      }
    } catch(e) {
      console.log(e)
    }
  }