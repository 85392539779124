import page, {UPDATE_PAGE, TRANSITION_IN_PAGE} from './page';

export const PageNamespace = 'page';

// export const WorkMutationTypes = {
//     CHANGE_PROJECT_SHOWN: `${PageNamespace}/${CHANGE_PROJECT_SHOWN}`,
//     CHANGE_PROJECT_COLOR: `${PageNamespace}/${CHANGE_PROJECT_COLOR}`,
// };
export const PageActionTypes = {
    UPDATE_PAGE: `${PageNamespace}/${UPDATE_PAGE}`,
    TRANSITION_IN_PAGE: `${PageNamespace}/${TRANSITION_IN_PAGE}`,
};

export default page;
