
import LightBoxState from '~/data/enum/LightBoxState';
import RouteNames from '~/data/enum/RouteNames';
import { mapState, mapMutations } from 'vuex';
import { PageNamespace } from '../store/module/page';
import { ControlNamespace, ControlMutationTypes } from '../store/module/control';
import { TweenLite, Expo } from '../node_modules/gsap/TweenMax';
import GeneralFooter from '../components/GeneralFooter/GeneralFooter.vue';

if(process.browser) {
  const scrollToPlugin = require('gsap/ScrollToPlugin');
}

export default {
  components: {
    // OverlayPageTransition,
    GeneralFooter,
    AppNavigation: () => import('~/components/AppNavigation/AppNavigation.vue'),
  },

  data() {
    return {
      LightBoxState,
      RouteNames,
    }
  },

  computed: {
    ...mapState(PageNamespace, ['page']),
    ...mapState(ControlNamespace, ['lightBoxState', 'lightBoxData', 'experienceStarted', 'showMenu']),
  },

  mounted() {
    var mql = window.matchMedia('(max-width: 768px)');
    mql.addListener(this.handleMatchMedia);
    this.handleMatchMedia(mql);
    // TweenLite.to(this.$el.querySelector('.links'), 1, {opacity: 0});
  },

  methods: {
    ...mapMutations({
      setShowMenu: ControlMutationTypes.SHOW_MENU,
      changeMobileSize: ControlMutationTypes.IS_MOBILE_SIZE,
    }),

    handleMatchMedia(e) {
      if (e.matches) {
        this.changeMobileSize(true);
      } else {
        this.changeMobileSize(false);
      }
    },

    handleCloseLightBox() {

    },

    onShowMenu(value = true) {
      this.setShowMenu(value);

     
    }
  },
  watch: {
    showMenu(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
        const size = Math.max(window.innerWidth, window.innerHeight);
        TweenLite.to(this.$refs.menuOverlay, 2, {width: size * 3, height: size * 3, ease: Expo.easeOut})
      } else {
        TweenLite.to(this.$refs.menuOverlay, 2, {width:0, height: 0, ease: Expo.easeOut})
        document.body.style.overflow = 'auto';
      }
    }
  }
}